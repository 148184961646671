import { ConnectConfiguratie } from './environment.service';

class ConversieConfiguratie extends ConnectConfiguratie {
  configurationName(): string {
    return 'Conversie';
  }

  production(): boolean {
    return false;
  }

  DEBUG(): boolean {
    return true;
  }

  getPrefix(): string {
    return null;
  }
  isSecure(): boolean {
    return true;
  }
  idpUrl(): string {
    return `https://inloggen.inkijk.somtoday.nl`;
  }
  idpIssuer(): string {
    return `https://inkijk.somtoday.nl`;
  }
  apiUrl(): string {
    return `https://api.connect.inkijk.somtoday.nl/`;
  }

  dashboardApiUrl(): string {
    return `https://etl.connect.inkijk.somtoday.nl`;
  }

  frontendUrl(): string {
    return `https://connect.inkijk.somtoday.nl/oauth`;
  }

  analyticsId(): string {
    return 'UA-163203739-2';
  }

  bureauICEKoppelpartijUUID(): string {
    return '10d97656-ed1e-4588-9834-aab9b53924e5';
  }

  wiserClientId(): string {
    return 'db07210a-9550-44af-ba79-64a236ea5a4d';
  }

  validationJwks(): object {
    return {keys: [{kty: 'RSA', 'x5t#S256': 'Wh0jOB_Ghd_O04p-y6O1D5dq5qNcbMF4JXRsx0yVrYA', e: 'AQAB', use: 'sig', kid: 'iridiumidp-41428306467553118374635884089323719554', x5c: ['MIIDSzCCAjMCEB8qzTV34SWHh0vNlXlOi4IwDQYJKoZIhvcNAQELBQAwZDELMAkGA1UEBhMCTkwxGjAYBgNVBAoMEVRvcGljdXMuRWR1Y2F0aW9uMREwDwYDVQQLDAhTb210b2RheTEmMCQGA1UEAwwdU29tdG9kYXkgSW5raWprIEF1dGhlbnRpY2F0b3IwHhcNMjExMjAzMDkxNzI3WhcNMjYxMjMxMjI1OTU5WjBkMQswCQYDVQQGEwJOTDEaMBgGA1UECgwRVG9waWN1cy5FZHVjYXRpb24xETAPBgNVBAsMCFNvbXRvZGF5MSYwJAYDVQQDDB1Tb210b2RheSBJbmtpamsgQXV0aGVudGljYXRvcjCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAOD0llgohRbzGRPwlNEGscWe+ZijTemy8CfjlJhrA/3Cme8NJNX0da3GW8Zjkq+jn1lH6wNtsgWdYo+khGhVk8/s9XxUHd+/HYtCFwLTiLTe57uxpfllBZZDEe6mCVmhmkJmarZNvpDALmV94BlvRGPCp8fvpSJbq0EtqftR1+WOTXHFty6bwwAO70Gyy7w5a6KJ/tFSSV/Qb/X4UxRusKC27zySAUatYoCnXuLmZI+k+parZ/CQ82amD872NJar2bLht5ZJmb3Y3I4qmQuAp8BZlCw/+0Y38whguElTkmFRo1JEQYpNsnqbrhR87Gti/RhwFGZFiV1xeI5zFkjSwtcCAwEAATANBgkqhkiG9w0BAQsFAAOCAQEAjbokVdUiyK+02q1rORUtnQOfATii+LThtjaHh5FnhIJJMan8W8ZQ6SK1fn2ewFPlSUPbVzRn6ZrsWymhWCt6614x5eESI0Ow+r3ANcB3GOBHGr+qho30175yWKeiqMrmZkZgcG8wctzb9UzshfGftKmjWTEBzz29nkmJq/8j08RcjwZBaSXGnYmvL9IkSPVvRfaf9MfrPhbHfbGhoKxK2YQSZmbgEYbwdWfmwDItjA4+T1/W8yaJ8tThGm6pEhPktoQTebSnlH8QaqaMtEDTxx+t+TJTWTUBbUBJ0rTS9SfEsJGaTjSGmQDiwGDfkAwgP9wcl7/DRfrLBhNoHrsVwQ=='], alg: 'RS256', n: '4PSWWCiFFvMZE_CU0QaxxZ75mKNN6bLwJ-OUmGsD_cKZ7w0k1fR1rcZbxmOSr6OfWUfrA22yBZ1ij6SEaFWTz-z1fFQd378di0IXAtOItN7nu7Gl-WUFlkMR7qYJWaGaQmZqtk2-kMAuZX3gGW9EY8Knx--lIlurQS2p-1HX5Y5NccW3LpvDAA7vQbLLvDlroon-0VJJX9Bv9fhTFG6woLbvPJIBRq1igKde4uZkj6T6lqtn8JDzZqYPzvY0lqvZsuG3lkmZvdjcjiqZC4CnwFmULD_7RjfzCGC4SVOSYVGjUkRBik2yepuuFHzsa2L9GHAUZkWJXXF4jnMWSNLC1w'}]};
  }
}

const conversieConfig: ConversieConfiguratie = new ConversieConfiguratie();

export const environment = conversieConfig.build();
